import React from "react";
import SingleJobPage from "../../components/SingleJobPage";
import withLayout from "../../layout";
import SEO from "../../components/SEO";
import {injectIntl} from "react-intl";

/**
 * note that the key we defined in role is needed for building the SingleJobPage
 * component with the right translation strings and media
 * @returns { SingleJobPage }
 * @constructor
 */

const SingleJobPageWrapper = ({intl}) => {
    const IntlTextObj = {
        role: "frontend.junior.title",
        roleValue: "Front-end JR",
        skills: {
            experience_title: "frontend.experience_title",
            experience: "frontend.junior.experience",
            employment_title: "frontend.employment_title",
            employment: "frontend.employment",
            placeofwork_title: "frontend.placeofwork_title",
            placeofwork: "frontend.placeofwork",
            title_left: "frontend.title_left",
            text_left: "frontend.text_left",
            title_right: "frontend.title_right",
            text_right: "frontend.text_right",
            text_center: "frontend.text_center",
        },
        hard_skills_title: "frontend.hard_skills_title",
        hard_skills: ["frontend.junior.hard_skill_1", "frontend.junior.hard_skill_2", "frontend.junior.hard_skill_3", "frontend.junior.hard_skill_4", "frontend.junior.hard_skill_5", "frontend.junior.hard_skill_6", "frontend.junior.hard_skill_7", "frontend.junior.hard_skill_8", "frontend.junior.hard_skill_9"],
        soft_skills_title: "frontend.soft_skills_title",
        soft_skills: ["frontend.junior.soft_skills_1", "frontend.junior.soft_skills_2", "frontend.junior.soft_skills_3", "frontend.junior.soft_skills_4"],
        adventure_title_thin: "frontend.adventure_title_thin",
        adventure_title: "frontend.adventure_title",
        benefits_title: "frontend.benefits_title",
        benefits: ["frontend.benefit_1", "frontend.benefit_2", "frontend.benefit_3", "frontend.benefit_4", "frontend.benefit_5"],
        offer_title: "frontend.offer_title",
        offer: ["frontend.offer_1", "frontend.offer_2", "frontend.offer_3", "frontend.offer_4", "frontend.offer_5"],
    };
    const keywords = intl.formatMessage({id:'frontend.meta_keywords'}).split(',') || [];
    return <>
        <SEO title={intl.formatMessage({id: 'frontend.meta_title'})}
             description={intl.formatMessage({id: 'frontend.meta_description'})} keywords={keywords} lang={intl.locale}/>
        <SingleJobPage IntlTextObj={IntlTextObj} intl={intl}/>
    </>
};

const customProps = {
    localeKey: "frontend", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(SingleJobPageWrapper));
